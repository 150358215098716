<template>
    <HeaderComponent/>
    <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="w-full h-[250px] mt-[70px] relative flex justify-center items-center bg-orange-100">
        <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center overflow-hidden">
            <img src="../assets/images/fond3.jpg" alt="" class="absolute w-full h-full object-cover">
        </div>
        <div class="w-full h-full absolute top-0 left-0 bg-gray-800/40 flex justify-center items-center">
            <div class="container max-w-[1024px] px-[20px] flex justify-center items-center w-full h-full relative">
                <div class="w-full flex flex-col justify-center mt-[-30px] md:mt-0 relative h-full items-start">
                    <h1 class="text-white text-3xl font-bold">Contactez Nous</h1>
                    <p class="w-[300px] text-sm font-bold mt-[10px] text-gray-200">Prenez contact avec nous maintenant.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="w-full py-[50px] relative flex justify-center bg-gray-100 items-center">
        <div class="container max-w-[1024px] px-[20px] flex justify-center items-center w-full h-full relative">
            <div class="w-full relative flex md:justify-between justify-center items-start md:flex-row flex-col-reverse">
                <div class="md:w-[300px] w-full mt-[25px] md:mt-0">
                    <h1 class="text-3xl font-bold text-gray-800">Comment pouvons nous vous aider ?</h1>
                    <p class="text-sm text-gray-500 my-[30px] font-semibold">
                        Vous êtes au coeur, pour qu'on trouve ensemble ce dont vous avez réellement besoin
                    </p>
                    <div class="w-full h-[200px] pl-[25px] border-l-4 border-gray-200 flex flex-col">
                        <div class="mb-[10px] couleur relative">
                            <h1 class="text-gray-800 font-bold text-sm">1. Laissez nous un message</h1>
                            <p class="text-gray-500 mt-[10px] text-xs">
                                Ecrivez nous via le formulaire de contact, par mail ou sur whatsapp.
                            </p>
                        </div>
                        <div class="mb-[10px] couleur relative">
                            <h1 class="text-gray-800 font-bold text-sm">2. Nous prendrons contact avec vous</h1>
                            <p class="text-gray-500 mt-[10px] text-xs">
                                Après avoir reçu votre message, nous vous contacterons par whatsapp pour discuter et convenir ensemble des produits ainsi que des prix
                            </p>
                        </div>
                        <div class="mb-[10px] couleur relative">
                            <h1 class="text-gray-800 font-bold text-sm">3. Passez à la boutique prendre votre colis ou faites vous livrer n'importe où</h1>
                        </div>
                    </div>
                </div>
                <div class="md:w-[calc(100%-400px)] mt-[-120px] w-full pb-[20px] bg-white overflow-hidden rounded-[10px] shadow-4xl">
                    <div class="w-full h-[70px] bg-gray-300 md:p-[15px] p-[10px] flex justify-start items-center">
                        <div class="w-[40px] h-[40px] min-w-[40px] rounded-[5px] bg-gray-800 text-white flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 .64L8.23 3H5v2L2.97 6.29C2.39 6.64 2 7.27 2 8v10a2 2 0 0 0 2 2h16c1.11 0 2-.89 2-2V8c0-.73-.39-1.36-.97-1.71L19 5V3h-3.23M7 5h10v4.88L12 13L7 9.88M8 6v1.5h8V6M5 7.38v1.25L4 8m15-.62L20 8l-1 .63M8 8.5V10h8V8.5Z"/></svg>
                        </div>
                        <p class="text-xs font-semibold text-gray-600 pl-[10px]">
                            Ecrivez nous <span class="md:contents hidden"> en quelques mots si possible </span> vos besoins et nous ferons diligence pour vous répondre dans les 24h
                        </p>
                    </div>
                    <form @submit.prevent="sendMessage" class="w-full md:p-[15px] p-[10px] relative">
                        <div class="w-full relative flex justify-between items-center">
                            <div class="w-[48%] my-[10px] relative">
                                <input type="text" name="nom" v-model="nom" required class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Nom">
                            </div>
                            <div class="w-[48%] my-[10px] relative">
                                <input type="text" name="prenoms" v-model="prenoms" required class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Prénoms">
                            </div>
                        </div>
                        <div class="w-full relative flex justify-between items-center">
                            <div class="w-[48%] my-[10px] relative">
                                <input type="text" name="email" v-model="email" required class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Email">
                            </div>
                            <div class="w-[48%] my-[10px] relative">
                                <input type="text" name="numero" v-model="numero" required class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Numéro">
                            </div>
                        </div>
                        <div class="w-full mt-[50px] my-[10px] relative">
                            <h1 class="text-sm font-bold mb-[5px] text-gray-800">Optionnel</h1>
                            <textarea type="text" name="message" v-model="content" class="w-full h-[120px] resize-none p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Votre Message"></textarea>
                        </div>
                        <div class="w-full my-[10px] relative">
                            <button type="submit" class="w-full h-[45px] flex justify-center items-center p-[10px] outline-none text-sm font-bold rounded-[5px] bg-orange-600 text-gray-100">
                                <span v-if="!isLoading ">Envoyer</span>
                                <div v-if="isLoading" class="spinner1">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </button>
                        </div>
                        <div class="w-full my-[10px] relative">
                            <h1 class="text-xs text-gray-500 text-center">Si vous avez un problème plus urgent contactez nous au <a href="mailto:pommecity1@gmail.com" class="text-gray-800 font-bold hover:underline">pommecity1@gmail.com</a></h1>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section class="mt-[50px] py-[50px] bg-white flex justify-center items-center w-full relative">
        <div class="container flex px-[20px] max-w-[1024px] flex-col justify-center items-center w-full relative">
            <h1 class="text-center font-bold text-gray-800 text-2xl mb-[25px]">Obtenir notre Localisation !</h1>
            <div class="md:flex justify-between items-center w-full relative">
                <div class="md:w-[calc(50%-10px)] my-[10px] w-full md:h-[400px] h-[300px] flex flex-col relative justify-center items-center">
                    <div class="w-full h-[90%] relative overflow-hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3936.74039227144!2d2.611693073532404!3d9.356225783783998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10320514d61a193f%3A0x51bbeb8c5b7987c3!2sPomme%20City%20(Parakou)!5e0!3m2!1sfr!2sbj!4v1724857594964!5m2!1sfr!2sbj" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <h1 class="text-center mt-[10px] text-sm font-bold text-gray-800">Pomme City, Parakou</h1>
                </div>
                <div class="md:w-[calc(50%-10px)] my-[10px] w-full md:h-[400px] h-[300px] flex flex-col relative justify-center items-center">
                    <div class="w-full h-[90%] relative overflow-hidden">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.1811903533703!2d2.419482873503098!3d6.370593024924434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x102355a2f665290b%3A0x608d1e1de8196425!2sPomme%20City!5e0!3m2!1sfr!2sbj!4v1724858033639!5m2!1sfr!2sbj" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <h1 class="text-center mt-[10px] text-sm font-bold text-gray-800">Pomme City, Cotonou</h1>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>

</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref } from 'vue'
import FooterComponent from '@/components/FooterComponent.vue'
import axios from "axios"

const nom = ref('')
const prenoms = ref('')
const email = ref('')
const numero = ref('')
const content = ref('')
const successMessage = ref('')
const errorMessage = ref('')
const isLoading = ref(false)

const sendMessage = async () => {
    isLoading.value = true
    try {
        const response = await axios.post('/api/messages/send', 
        {
            nom: nom.value,
            prenoms: prenoms.value,
            numero: numero.value,
            email: email.value,
            content: content.value,
        });
        if (response.status === 201) {
            successMessage.value = 'Message envoyé avec succès!';
            hideSuccessMessageAfterDelay();
        }
        nom.value = ""
        prenoms.value = ""
        numero.value = ""
        email.value = ""
        content.value = ""
    } catch (error) {
        errorMessage.value = 'Erreur lors de l\'envoi du message.';
        hideErrorMessageAfterDelay()
    } finally {
        isLoading.value = false
    }
};


const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};


</script>

<style>
    .couleur::before {
        content: '';
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #ea580c;
        position: absolute;
        top: 2px;
        left: -35px;
    }
</style>
