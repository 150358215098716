<template>
  <HeaderComponent />
  <section class="w-full mt-[70px] flex relative justify-center items-center bg-gray-200">
    <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-start items-center py-[25px] md:py-[40px]">
      <h1 class="text-gray-500 font-bold text-xs">
        <router-link to="/">Accueil</router-link> /
        <router-link to="/boutique"> Boutique</router-link> /
        <span class="text-orange-600">{{ produit.name }}</span>
      </h1>
    </div>
  </section>
  <div class="w-[400px] max-w-[80%] flex flex-col justify-center items-center fixed top-[80px] z-50 left-[50%] translate-x-[-50%]">
      <div v-if="ajouterProduit" class="w-auto text-center p-[10px] rounded-[5px] my-[5px] bg-green-600 text-white text-sm font-bold">
          {{ ajouterProduit }}
      </div>
  </div>
  <section class="w-full my-[50px] pb-[80px] relative flex justify-center items-center">
      <div class="container max-w-[1024px] w-full relative flex justify-center items-center px-[20px]">
          <div class="w-full relative flex md:flex-row flex-col md:justify-between justify-center items-start">
              <div class="md:w-[calc(50%)] md:pr-[20px] w-full flex flex-col justify-center items-start">
                  <div class="w-full flex md:flex-row flex-col justify-between items-start">
                      <div class="md:w-[calc(100%-100px)] w-full h-[350px] relative">
                          <img :src="selectedImage" alt="Product Image" class="object-contain object-top w-full h-full">
                      </div>
                      <div v-if="produit.photos && produit.photos.length" class="flex md:flex-col justify-start mt-[20px] md:mt-0 items-center md:mb-[10px] mr-[10px] md:mr-0">
                          <div v-for="(image, index) in produit.photos" :key="index" @click="selectedImage = image" class="w-20 h-20 bg-gray-100 md:mb-[10px] mr-[10px] md:mr-0 rounded-lg overflow-hidden cursor-pointer border-2" :class="{'border-blue-500': selectedImage === image, 'border-transparent': selectedImage !== image}">
                              <img :src="image" alt="Thumbnail" class="object-cover w-full h-full">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="md:w-[calc(50%)] md:pl-[20px] w-full mt-4 md:mt-0 md:ml-6">
                  <h1 class="text-2xl font-bold text-gray-800">{{ produit.name }}</h1>
                  <p class="text-gray-600 md:line-clamp-2 line-clamp-6 my-[15px] text-sm">{{ produit.description }}</p>
                  <div class="flex items-center mb-[10px]">
                    <div class="flex">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star-half-stroke"></i>
                    </div>
                    <span class="mx-[10px]">
                      |
                    </span>
                    <p class="text-green-600 text-sm font-bold">En stock</p>
                  </div>
                  <div v-if="distinctPrices.length">
                      <h3 class="text-sm font-bold text-gray-700">Sélectionnez un produit:</h3>
                      <ul class="mt-[10px]">
                          <li v-for="priceDetail in distinctPrices" :key="priceDetail.price" @click="selectProduct(priceDetail)" :class="{'bg-blue-200': selectedProduct === priceDetail, 'bg-gray-200': selectedProduct !== priceDetail}" class="flex flex-col mb-[15px] p-2 rounded-lg cursor-pointer">
                              <p class="flex items-end">
                                <span class="text-xl font-bold text-gray-800">{{ priceDetail.price }} FCFA</span>
                                <span v-if="priceDetail.originalPrice !== priceDetail.price" class="text-gray-500 text-sm ml-[10px] line-through">{{ priceDetail.originalPrice }} FCFA</span>
                              </p>
                              <div v-if="priceDetail.capacity" class="text-gray-600 text-sm">{{ priceDetail.capacity }}</div>
                              <div v-if="priceDetail.color" class="text-xs text-gray-600">{{ priceDetail.color }}</div>
                          </li>
                      </ul>
                  </div>
                  <div class="w-full relative hidden md:flex justify-between items-center mt-[50px]">
                      <button @click="addToCart" class="w-[45%] p-[10px] rounded-[5px] bg-gray-200 text-gray-800 border text-sm font-bold">Ajouter au panier</button>
                      <button @click="payNow" class="w-[45%] p-[10px] rounded-[5px] bg-gray-800 text-white border text-sm font-bold">Payer maintenant</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="fixed z-50 bottom-0 left-0 right-0 md:hidden w-full shadow-4xl bg-white p-[20px] flex justify-between items-center">
          <button @click="addToCart" class="w-[45%] p-[10px] rounded-[5px] bg-gray-200 text-gray-800 border text-sm font-bold">Ajouter au panier</button>
          <button @click="payNow" class="w-[45%] p-[10px] rounded-[5px] bg-gray-800 text-white border text-sm font-bold">Payer maintenant</button>
      </div>
  </section>
  <div v-if="isLoading" class="h-[calc(100vh-110px)] md:h-full bg-gray-200/90 w-full bottom-[-200px] fixed top-[70px] flex justify-center items-center">
    <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import axios from "axios"
import { useCartStore } from '../store/useCartStore.ts'

const router = useRouter()
const produit = ref({})
const isLoading = ref(true)
const selectedImage = ref('')
const selectedProduct = ref(null)
const ajouterProduit = ref ('')
const distinctPrices = ref([])
const category = ref('')

const produitId = router.currentRoute.value.params.id;
const cartStore = useCartStore()

const fetchProduitById = async () => {
try {
  const response = await axios.get(`/api/lots/${produitId}`);
  produit.value = response.data;
  selectedImage.value = produit.value.photos[0];  // Affiche la première image par défaut
  category.value = response.data.categorie
  const productDetails = produit.value.produits
  .filter(prod => prod.isOnline)
  .map(prod => ({
    id: prod._id,
    imei1: prod.imei1,
    imei2: prod.imei2,
    price: prod.prix_reduit || prod.prix,
    originalPrice: prod.prix,
    capacity: prod.capacities[0] || null,
    color: prod.colors[0] || null,
  }));

  // Obtenir des prix distincts
  distinctPrices.value = [...new Map(productDetails.map(item => [item.price, item])).values()];

  if (distinctPrices.value.length > 0) {
    selectedProduct.value = distinctPrices.value[0]; // Sélectionne par défaut le premier produit
  }
} catch (err) {
  console.error('Erreur lors du chargement du produit:', err);
} finally {
  isLoading.value = false;
}
};

const selectProduct = (productDetail) => {
selectedProduct.value = productDetail;
}

const hideajouterProduitAfterDelay = () => {
setTimeout(() => {
  ajouterProduit.value = ''; 
}, 1000);
};

const addToCart = () => {
if (!selectedProduct.value) return;

const productToAdd = {
  id: produit.value._id,
  productId: selectedProduct.value.id,
  imei1: selectedProduct.value.imei1,
  imei2: selectedProduct.value.imei2,
  name: produit.value.name,
  price: selectedProduct.value.price,
  category: category.value,
  originalPrice: selectedProduct.value.originalPrice,
  image: selectedImage.value,
  capacity: selectedProduct.value.capacity,
  color: selectedProduct.value.color,
  quantity: 1, // Vous pouvez ajouter une logique pour gérer les quantités
}
cartStore.addToCart(productToAdd)
ajouterProduit.value = 'Produit ajouté au panier'
hideajouterProduitAfterDelay();
};

const payNow = () => {
addToCart();
router.push('/panier');
}

onMounted(fetchProduitById);

</script>

<style scoped>
.shadow-4xl {
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

/* Style du loader */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: rgb(234, 88, 12);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>