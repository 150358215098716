<template>
    <swiper
      :modules="modules"
      :slides-per-view="getSlidesPerView"
      :breakpoints="{
          1024: {
            slidesPerView: 3
          },
          0: {
            slidesPerView: getSlidesPerView
          }
      }"
      :loop="true"
      :autoplay="{ delay: 5000, disableOnInteraction: false }"
      :pagination="{ clickable: true }"
    >
        <swiper-slide  v-for="(commentaire, index) in commentaires" :key="index" class="w-[300px] min-w-[300px] h-[300px] p-[10px] flex flex-col items-center justify-center">
            <div id="flech" class="w-full h-[120px] text-sm font-bold relative text-white rounded-[10px] bg-gray-800 p-[20px]">
                <i :class="commentaire.icone"></i>
                <p class="line-clamp-5 text-sm font-bold mt-[20px]">
                    {{ commentaire.content }}
                </p>
            </div>
            <div class="w-full flex items-center ml-[50px] m-[15px]">
                <div class="w-[35px] h-[35px] rounded-[50%] flex justify-center items-center overflow-hidden relative">
                    <img :src="commentaire.image" alt="" class="w-full h-full absolute object-contain">
                </div>
                <div class="ml-[10px]">
                    <h1 class="text-sm font-bold text-gray-800">{{ commentaire.nom }}</h1>
                    <p class="capitalize text-xs text-gray-400 font-semibold">{{ commentaire.poste }}</p>
                </div>
            </div>
        </swiper-slide>
    </swiper>
</template>
  
<script>
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
        commentaires: [
            {
                id: '',
                icone: 'fa-brands fa-facebook',
                nom: 'Abel Daho',
                poste: 'Je recommande',
                content: 'Bonne boutique des téléphones',
                image: './img/avatar.jpeg',
            },
            {
                id: '',
                icone: 'fa-brands fa-facebook',
                nom: 'Niga',
                poste: 'Je recommande',
                content: 'Bonne boutique des téléphones',
                image: './img/avatar.jpeg',
            },
            {
                id: '',
                icone: 'fa-brands fa-facebook',
                nom: 'Caporal Lee Huitpirate',
                poste: 'Je recommande',
                content: 'Bonne boutique de phone a base prix.',
                image: './img/avatar.jpeg',
            },
            {
                id: '',
                icone: 'fa-brands fa-facebook',
                nom: 'MD. Mostakim',
                poste: 'Je recommande',
                content: 'Bonne boutique des téléphones',
                image: './img/avatar.jpeg',
            },
        ],
    };
  },
  computed: {
    getSlidesPerView() {
      const screenWidth = window.innerWidth;
      return Math.floor(screenWidth / 360); // Adjusted for padding and margins
    },
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, A11y],
    };
  },
};
</script>

<style scoped>
/* Add any additional styles here */
.mySwiper .swiper-pagination {
  bottom: 20px; /* Adjust this value to move the pagination bullets up or down */
}
</style>
