import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./assets/style.css"
import axios from 'axios'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
axios.defaults.baseURL = process.env.VUE_APP_API_URL

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(store)


app.mount('#app');