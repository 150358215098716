import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BoutiqueView from '@/views/BoutiqueView.vue'
import ComparatifView from '@/views/ComparatifView.vue'
import ContactView from '@/views/ContactView.vue'
import PanierView from '@/views/PanierView.vue'
import DetailsView from '@/views/DetailsView.vue'
import OffresView from '@/views/OffresView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      description: "Découvrez Pomme City, votre boutique en ligne dédiée à la vente d'iPhones, Samsung et accessoires de qualité. Profitez des dernières offres et d'une expérience d'achat fluide.",
      keywords: "Pomme City, vente iPhone, vente Samsung, accessoires téléphones, boutique en ligne, smartphones",
      title: "Pomme City - Accueil",
      scrollToTop: true
    }    
  },
  {
    path: '/boutique',
    name: 'boutique',
    component: BoutiqueView,
    meta: {
      description: "Explorez notre large sélection de smartphones iPhone, Samsung et accessoires de téléphone sur Pomme City. Trouvez le produit parfait pour vous.",
      keywords: "Pomme City, iPhone, Samsung, accessoires téléphones, achat en ligne, smartphones",
      title: "Pomme City - Boutique",
      scrollToTop: true
    }    
  },
  {
    path: '/comparatif',
    name: 'comparatif',
    component: ComparatifView,
    meta: {
      description: "Comparez les iPhones, Samsung et accessoires disponibles sur Pomme City. Trouvez le meilleur produit qui correspond à vos besoins.",
      keywords: "Pomme City comparatif, iPhone, Samsung, accessoires, comparaison smartphones",
      title: "Pomme City - Comparatif",
      scrollToTop: true
    }    
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      description: "Contactez Pomme City pour toute question ou assistance concernant nos produits. Nous sommes là pour vous aider à trouver le smartphone ou l'accessoire parfait.",
      keywords: "Pomme City contact, service client, assistance, support, iPhone, Samsung, accessoires",
      title: "Pomme City - Contact",
      scrollToTop: true
    }    
  },
  {
    path: '/panier',
    name: 'panier',
    component: PanierView,
    meta: {
      description: "Revoyez et finalisez vos achats sur Pomme City. Votre panier contient les meilleurs iPhones, Samsung et accessoires à prix compétitifs.",
      keywords: "Pomme City panier, achats en ligne, iPhone, Samsung, accessoires, récapitulatif",
      title: "Pomme City - Panier",
      scrollToTop: true
    }    
  },
  {
    path: '/boutique/produits/:id',
    name: 'details',
    component: DetailsView,
    meta: {
      description: "Explorez notre large sélection de smartphones iPhone, Samsung et accessoires de téléphone sur Pomme City. Trouvez le produit parfait pour vous.",
      keywords: "Pomme City, iPhone, Samsung, accessoires téléphones, achat en ligne, smartphones",
      title: "Pomme City - Boutique",
      scrollToTop: true
    }
  },
  {
    path: '/bon-plan',
    name: 'offre',
    component: OffresView,
    meta: {
      description: "Ne manquez pas les bons plans de Pomme City ! Découvrez nos offres exclusives sur les iPhones, Samsung et accessoires de téléphones.",
      keywords: "Pomme City bons plans, réductions, offres spéciales, iPhone, Samsung, accessoires",
      title: "Pomme City - Bon Plan",
      scrollToTop: true
    }    
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (to.meta.scrollToTop) {
      return { top: 0 };
    }
  },
})

router.beforeEach((to: any, from: any, next) => {
  document.title = to.meta.title || "Caille d'Or";
  
  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta && to.meta.description) {
    descriptionMeta.setAttribute('content', to.meta.description);
  }

  const keywordsMeta = document.querySelector('meta[name="keywords"]');
  if (keywordsMeta && to.meta.keywords) {
    keywordsMeta.setAttribute('content', to.meta.keywords);
  }

  next();
});

export default router
