<template>
    <swiper
      :modules="modules"
      :slides-per-view="getSlidesPerView"
      :loop="true"
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index" class="w-[180px] min-w-[180px] max-w-[180px] mb-[50px] rounded-[10px] overflow-hidden h-[240px] mr-[20px] bg-gray-100 md:my-[30px] my-[20px] relative" @click="redirectToProduct(slide.lotId)">
          <div class="w-full h-[60%] bg-white overflow-hidden flex justify-center items-center relative">
              <img :src="slide.photos[0]" alt="" class="w-full h-full absolute object-contain object-center">
          </div>
          <div class="p-[10px] overflow-hidden rounded-b-[10px]">
              <h1 class="text-sm font-bold line-clamp-1 text-gray-600">{{ slide.lotName }}</h1>
              <p>
                  <span class="font-bold text-gray-700">{{ slide.prix_reduit }} <strong class="text-[12px]">FCFA</strong></span> <span class="text-xs text-gray-400 line-through" v-if="slide.prix !== slide.prix_reduit"> - {{ slide.prix }}F</span>
              </p>
              <div class="w-full absolute bottom-0 left-0 flex justify-between items-center">
                  <div class="pl-[10px] text-orange-500">
                      <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                      <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                      <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                      <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                      <i class="fa-solid fa-star-half text-[10px] mr-[5px]"></i>
                  </div>
                  <button class="p-[10px] text-xs font-bold bg-gray-300 rounded-br-[10px] text-gray-800 rounded-tl-[10px]"><span class="hidden md:flex">Voir plus</span> <span class="md:hidden">Plus</span></button>
              </div>
          </div>
      </swiper-slide>
    </swiper>
</template>
  
<script>
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import axios from "axios"

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slides: [],
    };
  },
  computed: {
    getSlidesPerView() {
      const screenWidth = window.innerWidth;
      return Math.floor(screenWidth / 150); // Adjusted for padding and margins
    },
  },
  methods: {
    async fetchHistories() {
      try {
        const response = await axios.get('/api/sales/most-sold-products');
        this.slides = response.data
      } catch (error) {
        console.error('Failed to fetch histories:', error);
      }
    },
    redirectToProduct(id) {
      this.$router.push(`/boutique/produits/${id}`);
    }
  },
  setup() {
    return {
      modules: [Autoplay, Pagination, A11y],
    };
  },
  mounted() {
    this.fetchHistories(); // Récupère les données quand le composant est monté
  },
};
</script>

<style scoped>
/* Add any additional styles here */
.mySwiper .swiper-pagination {
  bottom: 20px; /* Adjust this value to move the pagination bullets up or down */
}
</style>
