<template>
    <HeaderComponent/>
    <section class="w-full mt-[70px] flex relative justify-center items-center bg-gray-200">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-start items-center py-[25px] md:py-[40px]">
            <h1 class="text-gray-500 font-bold text-xs ">
                <router-link to="/">Accueil</router-link> / <span class="text-orange-600">Comparatif</span>
            </h1>
        </div>
    </section>
    <section class="my-[50px] w-full relative min-h-screen flex justify-center items-start">
        <div class="container max-w-[1024px] px-[20px] flex flex-col justify-center items-center relative w-full">
            <div class="py-[20px] rounded-[10px] bg-white w-full">
                <h1 class="text-sm font-bold text-gray-800">Recherchez des produits et comparez</h1>
                <div class="flex md:flex-row flex-col justify-center mt-[5px] items-center w-full relative">
                    <div class="w-full relative flex md:flex-row flex-col items-center">
                        <!-- Input pour le 1er produit avec suggestions -->
                        <div class="md:w-[calc(50%-10px)] my-[10px] md:my-0 w-full relative">
                            <label for="product1" class="uppercase text-gray-400 font-semibold text-xs absolute left-[10px] top-[5px]">1er produit</label>
                            <input v-model="produit1" @input="suggestions('produit1')" type="text" class="w-full p-[10px] rounded-[5px] text-sm font-bold text-gray-800 outline-none border-none bg-gray-100 pt-[22px]">
                            <ul v-if="suggestionsProduit1.length" class="absolute z-50 bg-white border mt-1 w-full max-h-[200px] overflow-y-auto">
                                <li v-for="(produit, index) in suggestionsProduit1" :key="index" @click="selectProduit('produit1', produit.name)" class="p-2 cursor-pointer hover:bg-gray-200">
                                    {{ produit.name }}
                                </li>
                            </ul>
                        </div>
                        <!-- Input pour le 2ème produit avec suggestions -->
                        <div class="md:w-[calc(50%-10px)] my-[10px] md:my-0 mx-[10px] w-full relative">
                            <label for="product2" class="uppercase text-gray-400 font-semibold text-xs absolute left-[10px] top-[5px]">2eme produit</label>
                            <input v-model="produit2" @input="suggestions('produit2')" type="text" class="w-full p-[10px] rounded-[5px] text-sm font-bold text-gray-800 outline-none border-none bg-gray-100 pt-[22px]">
                            <ul v-if="suggestionsProduit2.length" class="absolute z-50 bg-white border mt-1 w-full max-h-[200px] overflow-y-auto">
                                <li v-for="(produit, index) in suggestionsProduit2" :key="index" @click="selectProduit('produit2', produit.name)" class="p-2 cursor-pointer hover:bg-gray-200">
                                    {{ produit.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button @click="comparerProduits" class="text-center md:w-[100px] w-full my-[10px] md:my-0 text-sm font-bold p-[10px] py-[16px] text-white bg-gray-800 rounded-[5px]">Comparer</button>
                </div>
                <!-- Résultats de la comparaison -->
                <div v-if="resultatComparaison && produit1Data && produit2Data" class="mt-[20px] rounded-[10px]">
                    <h2 class="text-sm font-bold text-gray-800 mb-4">Résultats de la comparaison:</h2>
                    <table class="w-full bg-gray-100 border rounded-[10px] table-fixed">
                        <thead>
                            <tr class="bg-gray-200 text-gray-800">
                                <th class="p-2 text-left overflow-x-auto px-[10px]">Caractéristiques</th>
                                <th class="p-2 text-left overflow-x-auto px-[10px]">{{ produit1Data.name }}</th>
                                <th class="p-2 text-left overflow-x-auto px-[10px]">{{ produit2Data.name }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b">
                                <td class="p-2 text-gray-800 font-semibold">Image</td>
                                <td class="p-2">
                                    <img :src="produit1Data.photos[0]" alt="Image produit 1" class="w-[100px] h-auto">
                                </td>
                                <td class="p-2">
                                    <img :src="produit2Data.photos[0]" alt="Image produit 2" class="w-[100px] h-auto">
                                </td>
                            </tr>
                            <tr class="border-b">
                                <td class="p-2 text-gray-800 font-semibold">Prix</td>
                                <td class="p-2">
                                    <span v-for="(produit, index) in produit1Data.produits" :key="index" class="mr-[5px] block my-[5px] border border-gray-400 rounded-[5px] p-[5px]">
                                        {{ produit.prix_reduit }}
                                    </span>
                                </td>
                                <td class="p-2">
                                    <span v-for="(produit, index) in produit2Data.produits" :key="index" class="mr-[5px] block my-[5px] border border-gray-400 rounded-[5px] p-[5px]">
                                        {{ produit.prix_reduit }}
                                    </span>
                                </td>
                            </tr>
                            <tr class="border-b">
                                <td class="p-2 text-gray-800 font-semibold">Capacités disponibles</td>
                                <td class="p-2">
                                    <span v-for="(produit, index) in produit1Data.produits" :key="index" class="mr-[5px] block my-[5px] border border-gray-400 rounded-[5px] p-[5px]">
                                        {{ produit.capacities.join(', ') }}
                                    </span>
                                </td>
                                <td class="p-2">
                                    <span v-for="(produit, index) in produit2Data.produits" :key="index" class="mr-[5px] block my-[5px] border border-gray-400 rounded-[5px] p-[5px]">
                                        {{ produit.capacities.join(', ') }}
                                    </span>
                                </td>
                            </tr>
                            <tr class="border-b">
                                <td class="p-2 text-gray-800 font-semibold">Couleurs disponibles</td>
                                <td class="p-2">
                                    <span v-for="(produit, index) in produit1Data.produits" :key="index" class="mr-[5px] block my-[5px] border border-gray-400 rounded-[5px] p-[5px]">
                                        {{ produit.colors.join(', ') }}
                                    </span>
                                </td>
                                <td class="p-2">
                                    <span v-for="(produit, index) in produit2Data.produits" :key="index" class="mr-[5px] block my-[5px] border border-gray-400 rounded-[5px] p-[5px]">
                                        {{ produit.colors.join(', ') }}
                                    </span>
                                </td>
                            </tr>
                            <tr class="border-b">
                                <td class="p-2 text-gray-800 font-semibold">Description</td>
                                <td class="p-2">{{ produit1Data.description }}</td>
                                <td class="p-2">{{ produit2Data.description }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else-if="resultatComparaison" class="mt-[20px] bg-red-100 text-red-700 p-[10px] rounded-[10px]">
                    {{ resultatComparaison }}
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'
import { ref, onMounted } from 'vue'
import axios from "axios"

const produits = ref([])
const produit1 = ref('')
const produit2 = ref('')
const produit1Data = ref(null)
const produit2Data = ref(null)
const suggestionsProduit1 = ref([])
const suggestionsProduit2 = ref([])
const resultatComparaison = ref(null)
const isLoading = ref(false)

const fetchProduits = async () => {
  try {
    const response = await axios.get(`/api/lots`);
    // Filtrage pour n'inclure que les produits avec isOnline à true
    produits.value = response.data;
  } catch (err) {
    console.error('Erreur lors du chargement des produits:', err);
  } finally {
    isLoading.value = false;
  }
};

const suggestions = (inputName) => {
    const input = inputName === 'produit1' ? produit1.value : produit2.value;
    const suggestions = produits.value.filter(p => p.name.toLowerCase().includes(input.toLowerCase()));
    
    if (inputName === 'produit1') {
        suggestionsProduit1.value = suggestions;
    } else {
        suggestionsProduit2.value = suggestions;
    }
};

const selectProduit = (inputName, nomProduit) => {
    if (inputName === 'produit1') {
        produit1.value = nomProduit;
        suggestionsProduit1.value = [];
    } else {
        produit2.value = nomProduit;
        suggestionsProduit2.value = [];
    }
};

const comparerProduits = () => {
    produit1Data.value = produits.value.find(p => p.name.toLowerCase() === produit1.value.toLowerCase());
    produit2Data.value = produits.value.find(p => p.name.toLowerCase() === produit2.value.toLowerCase());

    if (produit1Data.value && produit2Data.value) {
        resultatComparaison.value = true;
    } else {
        resultatComparaison.value = 'Un ou les deux produits sont introuvables';
    }
};

onMounted(() => {
    fetchProduits()
})
</script>
