<template>
    <HeaderComponent/>
    <div class="fixed top-[80px] left-[50%] z-[100] translate-x-[-50%] w-[80%] max-w-[400px] flex flex-col">
        <div v-if="errorMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-red-700 my-[10px] font-bold text-center p-[20px]">
            {{ errorMessage }}
        </div>
        <div v-if="successMessage" class="text-sm w-full rounded-[5px] shadow-4xl text-white bg-green-700 my-[10px] font-bold text-center p-[20px]">
            {{ successMessage }}
        </div>
    </div>
    <section class="w-full md:h-[calc(100vh-70px)] mt-[70px] relative flex justify-center bg-gray-200 items-center">
        <div class="md:flex hidden justify-center items-center w-full h-full absolute">
            <div class="md:w-[60%] w-full h-full"></div>
            <div class="md:w-[40%] w-full h-full bg-white"></div>
        </div>
        <div class="container max-w-[1024px] px-[20px] flex md:flex-row flex-col justify-center items-center w-full h-full relative">
            <div class="md:w-[60%] w-full h-full overflow-y-auto parent md:p-[50px] py-[50px]">
                <div class="w-full relative min-h-[200px] bg-white rounded-[10px] p-[20px]">
                    <h1 class="text-xl font-bold text-gray-800 pb-[20px] border-b">Panier</h1>
                    <div v-for="(product, index) in cartStore.cart" :key="index" class="border-t border-b pt-[20px] relative">
                        <div class="w-full flex relative">
                            <div class="w-[50px] h-[60px] min-w-[50px] min-h-[60px] rounded-[5px] shadow-4xl flex justify-center items-center overflow-hidden">
                                <img :src="product.image" :alt="product.name">
                            </div>
                            <div class="w-full md:pl-[20px] pl-[10px] relative">
                                <div class="flex justify-between items-center w-full">
                                    <h1 class="text-sm text-gray-600 font-bold">{{ product.name }}</h1>
                                    <p class="text-sm font-bold flex items-end justify-end text-gray-700">{{ product.price * product.quantity }} <span class="text-[10px] ml-[5px]">FCFA</span></p>
                                </div>
                                <div class="text-[10px] font-semibold text-gray-500 w-full">
                                    {{ product.price }} <span class="text-[8px]">FCFA</span> | <span class="text-green-600">En stock</span>
                                </div>
                                <div  v-if="product.capacity || product.color" class="text-xs mb-[10px] text-gray-500 font-semibold flex items-center">
                                    Capacité : {{ product.capacity }} | Couleur : <div class="inline-block ml-[10px] text-xs text-gray-600 cursor-pointer">{{product.color}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex justify-between items-center my-[10px]">
                            <div>
                                <div class="p-[5px] font-semibold text-gray-500 text-xs rounded-[5px] border">
                                    <button @click="decreaseQuantity(index)">-</button>
                                    <span class="mx-[10px]">{{ product.quantity }}</span>
                                    <button @click="increaseQuantity(index)">+</button>
                                </div>
                            </div>
                            <div class="text-xs text-gray-400 cursor-pointer" @click="removeProduct(index)">
                                <i class="fa-solid fa-trash"></i> Supprimer
                            </div>
                        </div>
                    </div>
                    <div v-if="cartStore.cart.length == 0" class="w-full h-[150px] flex justify-center items-center text-sm font-bold text-center text-gray-800">
                        Aucun produit sélectionné
                    </div>
                </div>
                <div class="w-full bg-white rounded-[10px] mt-[20px] p-[20px]">
                    <h1 class="text-xl font-bold text-gray-800 pb-[20px] border-b">Vos informations personnelles</h1>
                    <form action="" class="w-full md:py-[15px] py-[10px] relative">
                        <div class="w-full relative md:flex justify-between items-center">
                            <div class="md:w-[48%] w-full my-[15px] relative">
                                <input type="text" name="" id="" v-model="nom" class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Nom">
                            </div>
                            <div class="md:w-[48%] w-full my-[15px] relative">
                                <input type="text" name="" id="" v-model="prenoms" class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Prénoms">
                            </div>
                        </div>
                        <div class="w-full relative md:flex justify-between items-center">
                            <div class="md:w-[48%] w-full my-[15px] relative">
                                <input type="text" name="" id="" v-model="email" class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Email">
                            </div>
                            <div class="md:w-[48%] w-full my-[15px] relative">
                                <input type="text" name="" id="" v-model="numero" class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Numéro">
                            </div>
                        </div>
                        <div class="w-full my-[10px] relative">
                            <input type="text" name="" id="" v-model="adresse" class="w-full p-[10px] outline-none text-sm font-semibold rounded-[5px] bg-gray-100" placeholder="Adresse">
                        </div>
                    </form>
                </div>
                <div class="w-full bg-white rounded-[10px] mt-[20px] p-[20px]">
                    <h1 class="text-xl font-bold text-gray-800 pb-[20px] border-b">Paiement</h1>
                    <div class="w-full flex py-[10px]">
                        <div @click="selectPayment('moov')" :class="{'border-blue-500 border-2': selectedPayment === 'moov'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl rounded-[5px] flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/moov.png" alt="Moov">
                        </div>
                        <div @click="selectPayment('mtn')" :class="{'border-blue-500 border-2': selectedPayment === 'mtn'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl mx-[20px] rounded-[5px] flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/mtn.png" alt="MTN">
                        </div>
                        <div @click="selectPayment('mastercard')" :class="{'border-blue-500 border-2': selectedPayment === 'mastercard'}" class="cursor-pointer w-[40px] h-[25px] shadow-4xl rounded-[5px] flex justify-center items-center overflow-hidden">
                            <img src="../assets/images/master.png" alt="Mastercard">
                        </div>
                    </div>
              
                    <div v-if="selectedPayment === 'mastercard' || selectedPayment === 'visa'" class="mt-[20px]">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Numéro de Carte</label>
                        <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="1234 5678 9123 4567">

                        <div class="w-full relative flex justify-between items-center">
                            <div class="w-[70%] relative">
                                <label class="block mt-[10px] mb-2 text-sm font-medium text-gray-900">Expiration</label>
                                <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="MM/AA">
                            </div>
                            <div class="w-[28%] relative">
                                <label class="block mt-[10px] mb-2 text-sm font-medium text-gray-900">CVC</label>
                                <input type="text" class="w-full p-2 border rounded-[5px] outline-none" placeholder="123">
                            </div>
                        </div>
                    </div>
                
                    <div v-if="selectedPayment === 'moov' || selectedPayment === 'mtn'" class="mt-[20px]">
                        <label class="block mb-2 text-sm font-medium text-gray-900">Numéro de Téléphone</label>
                        <input type="text" v-model="numeroPaiement" class="w-full p-2 border rounded-[5px] outline-none" placeholder="Entrez votre numéro">
                    </div>
                </div>
            </div>
            <div class="md:w-[40%] w-full h-full overflow-y-auto parent bg-white md:p-[50px] p-[25px] mb-[20px] md:mb-0 rounded-[10px] md:rounded-none">
                <h1 class="text-gray-800 text-xl mb-[20px] font-bold relative">Résumé d'achat</h1>
                <div class="w-full relative">
                    <!-- Montants par catégorie -->
                    <div class="w-full relative flex justify-between my-[10px] items-center">
                        <h1 class="text-xs font-semibold text-gray-500">Produits Apple:</h1>
                        <p class="text-sm font-bold text-gray-700">{{ totalByCategory.Apple || 0 }} <span class="text-[10px]">FCFA</span></p>
                    </div>
                    <div class="w-full relative flex justify-between my-[10px] items-center">
                        <h1 class="text-xs font-semibold text-gray-500">Produits Samsung:</h1>
                        <p class="text-sm font-bold text-gray-700">{{ totalByCategory.Samsung || 0 }} <span class="text-[10px]">FCFA</span></p>
                    </div>
                    <div class="w-full relative flex justify-between my-[10px] items-center">
                        <h1 class="text-xs font-semibold text-gray-500">Accessoires:</h1>
                        <p class="text-sm font-bold text-gray-700">{{ totalByCategory.Accessoires || 0 }} <span class="text-[10px]">FCFA</span></p>
                    </div>
                    <!-- Taxe -->
                    <div class="w-full relative flex justify-between py-[20px] border-b items-center">
                        <h1 class="text-xs font-semibold text-gray-500 uppercase">Taxe ({{ taxPercentage }}%): 
                            <i class="fa-solid fa-circle-info text-blue-500 cursor-pointer relative"
                                @mouseenter="showTaxInfo = true"
                                @mouseleave="showTaxInfo = false">
                                <div v-if="showTaxInfo" class="absolute left-0 font-mono top-[20px] bg-gray-700 text-white p-[10px] text-xs rounded-[5px] w-[200px]">
                                  Les taxes sont de 1.8% pour Moov et MTN, et de 4% pour les cartes bancaires.
                                </div>
                            </i>
                        </h1>
                        <p class="text-sm font-bold text-gray-700">{{ totalTax }} <span class="text-[10px]">FCFA</span></p>
                    </div>
                    <!-- Total -->
                    <div class="w-full relative flex justify-between py-[20px] items-center">
                        <div>
                            <h1 class="text-sm font-bold text-gray-800 uppercase">Total:</h1>
                            <p class="text-[10px] font-bold text-gray-500">inclus les taxes</p>
                        </div>
                        <p class="text-xl font-bold text-gray-800">{{ totalWithTax }} <span class="text-[10px]">FCFA</span></p>
                    </div>
                    <!-- Livraison gratuite -->
                    <div class="w-full p-[10px] py-[20px] rounded-[5px] bg-orange-100 relative">
                        <div class="w-full relative flex justify-between items-center">
                            <div>
                                <h1 class="text-[10px] font-bold text-gray-500">Livraison gratuite</h1>
                                <p class="text-sm font-bold text-gray-700">Cotonou - Parakou</p>
                            </div>
                            <p class="text-xl font-bold text-orange-600">0.00 <span class="text-[10px]">FCFA</span></p>
                        </div>
                    </div>
                    <button @click="payerCommande()" class="w-full mt-[20px] h-[45px] flex justify-center items-center p-[10px] rounded-[5px] bg-orange-600 text-white text-sm font-bold text-center">
                        <span v-if="!isLoading1">Confirmer la commande</span>
                        <div v-if="isLoading1" class="spinner1">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <div v-if="checking" class="w-full h-[calc(100vh-70px)] right-0 bg-gray-100 fixed bottom-0 top-[70px] z-10 flex justify-center items-center flex-col">
        <div class="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1 class="my-[10px] mt-[50px] text-sm font-bold text-gray-800">En attente de paiement !</h1>
        <button @click="toggleCheck()" class="p-[10px] bg-blue-600 font-bold text-sm text-white rounded-[5px]">
            Recommencer
        </button>
    </div>
    <div v-if="isDone" class="w-full h-[calc(100vh-70px)] right-0 bg-gray-100 fixed bottom-0 top-[70px] z-10 flex justify-center items-center flex-col">
        <h1 class="text-gray-600 font-bold text-xl">Tout est fait !</h1>
        <img src="../assets/images/all-done.png" alt="" class="h-[150px] my-[20px]">
        <p class="w-full px-[20px] max-w-[600px] text-center text-sm font-semibold text-gray-500">
            La commande a été enregistrée avec succès et la facture a été envoyé sur votre mail!
        </p>
        <button @click="reload()" class="text-white font-bold text-sm bg-gray-800/80 rounded-[5px] p-[10px] mt-[20px]"> Revenir à la Page des articles</button>
    </div>
    <div v-if="isLoading" class="w-full h-[calc(100vh-70px)] right-0 bg-gray-100 fixed bottom-0 top-[70px] z-10 flex justify-center items-center flex-col">
        <div class="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </div>

</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { ref, computed, onMounted } from 'vue'
import { useCartStore } from '@/store/useCartStore'
import axios  from 'axios'
import { useClientStore } from '@/store/useClientStore'
import { useRouter } from 'vue-router'

const router = useRouter()
const cartStore = useCartStore()

const clientStore = useClientStore()

const client = computed(() => clientStore.info);
const isLoading1 = ref(false)

const nom = ref('')
const prenoms = ref('')
const numero = ref('')
const email = ref('')
const adresse = ref('')
const numeroPaiement = ref('')
const ErrorMessage = ref('')
const checking = ref(false)
const isLoading = ref(false)
const isDone = ref(false)
const successMessage = ref('')
const errorMessage = ref('')

function toggleCheck() {
    checking.value = !checking.value
}

function reload() {
    router.push('/boutique')
}

function increaseQuantity(index) {
  cartStore.cart[index].quantity += 1
}

function decreaseQuantity(index) {
  if (cartStore.cart[index].quantity > 1) {
    cartStore.cart[index].quantity -= 1
  } else {
    removeProduct(index)
  }
}

function removeProduct(index) {
  cartStore.cart.splice(index, 1)
}

// Sélection du mode de paiement
const selectedPayment = ref('moov');
const selectPayment = (method) => {
  selectedPayment.value = method;
};

// Calcul des montants par catégorie
const totalByCategory = computed(() => {
  const categories = {
    Apple: 0,
    Samsung: 0,
    Accessoires: 0,
  };

  cartStore.cart.forEach(product => {
    if (product.category === 'Apple') {
      categories.Apple += product.price * product.quantity;
    } else if (product.category === 'Samsung') {
      categories.Samsung += product.price * product.quantity;
    } else if (product.category === 'Accessoires') {
      categories.Accessoires += product.price * product.quantity;
    }
  });

  return categories;
});


// Calcul de la taxe et du total
const taxPercentage = computed(() => {
  return selectedPayment.value === 'mastercard' || selectedPayment.value === 'visa' ? 4 : 1.8;
});

const totalTax = computed(() => {
  return Object.values(totalByCategory.value).reduce((sum, amount) => sum + (amount * taxPercentage.value / 100), 0);
});

const totalWithTax = computed(() => {
  return Object.values(totalByCategory.value).reduce((sum, amount) => sum + amount, 0) + totalTax.value;
});

const totalWithoutTax = computed(() => {
  return Object.values(totalByCategory.value).reduce((sum, amount) => sum + amount, 0);
});

// Affichage de l'info-bulle pour la taxe
const showTaxInfo = ref(false);

const payerCommande = async () => {
    isLoading1.value = true
    try {
        // Sauvegarder les informations client dans le store
        const clientInfo = {
            nom: nom.value,
            prenoms: prenoms.value,
            email: email.value,
            numero: numero.value,
            adresse: adresse.value
        }
        clientStore.setClientInfo(clientInfo)

        // Créer la description avec les informations du panier
        const productDescriptions = cartStore.cart.map(product => {
            return `${product.quantity}x ${product.name} (${product.capacity ? product.capacity : 'N/A'}, ${product.color ? product.color : 'N/A'})`
        }).join(', ');

        const CommandeData = {
            nom: nom.value,
            prenoms: prenoms.value,
            email: email.value,
            numero: numeroPaiement.value,
            prix: totalWithoutTax.value,
            description: `Paiement de ${totalWithTax.value} FCFA pour la commande des produits suivants: ${productDescriptions}`
        };
        const response = await axios.post('/paiement', CommandeData);

        window.location.href = response.data.redirectUrl;
    } catch (error) {
        errorMessage.value = 'Erreur lors du paiement';
        hideErrorMessageAfterDelay();
    } finally {
      isLoading1.value = false
    }
};

const createVente = async () => {
  try {
    isLoading.value = true
    
    const response = await axios.post('/api/sales', {
      produits: cartStore.cart.map(product => ({
        id: product.id,  // Inclure l'ID du produit
        lotName: product.name,
        lotCategorie: product.category,
        imei1: product.imei1,
        imei2: product.imei2,
        prix: product.prix,
        prix_reduit: product.prix_reduit,
        quantity: product.quantity,
        stotal: product.prix_reduit * product.quantity
      })),
      client: client.value,
      vendeur: {
        nom: "En ligne",
        prenoms: "En ligne",
        email: "En ligne",
        numero: "En ligne",
        boutique: "En ligne",
        poste: "En ligne"
      },
      total: totalWithoutTax.value,
      reductionMontant: 0,
      type: 'Vente',
      totalApresReduction: totalWithoutTax.value,
      moyenDePaiement: "Fedapay",
    })
    
    isDone.value = true
    produits.value = []
    cartStore.clearCart()
  } catch (error) {
    errorMessage.value = 'Erreur lors de la création de la vente'
    hideErrorMessageAfterDelay()
  } finally {
    isLoading.value = false 
  }
}


const urlParams = new URLSearchParams(window.location.search);
const id = urlParams.get('id');
const verification = ref({})
const verificationId = async () => {
  try {
    const response = await axios.get(`/verification/${id}`);
    verification.value = response.data
    if (verification.value.message === "Transaction approuvée"){
        createVente()()
    } else if (verification.value.message === "Transaction en attente") {
        checking.value= true
    } else {
        checking.value = true
    }
  } catch (err) {
    ErrorMessage.value = true;
  }
};

function traiterPaiement(status) {
  if (status === 'pending') {
    checking.value = true
  } else if (status === 'approved') {
    verificationId()
  } else {
    checking.value = true
  }
}

const checkUrlAndExecute = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status');

  if (status) {
    traiterPaiement(status);
  }
};

const hideSuccessMessageAfterDelay = () => {
    setTimeout(() => {
      successMessage.value = ''; 
    }, 3000);
};
  
const hideErrorMessageAfterDelay = () => {
    setTimeout(() => {
      errorMessage.value = ''; 
    }, 3000);
};

onMounted(() =>{
    checkUrlAndExecute()
});
</script>

<style>
</style>
