<template>
    <HeaderComponent/>
    <section class="w-full h-[400px] mt-[70px] relative flex justify-center items-center">
        <div class="w-full absolute top-0 left-0 h-full flex justify-center items-center overflow-hidden">
            <img src="../assets/images/fond1.jpg" alt="" class="w-full h-full absolute object-cover object-right">
        </div>
        <div class="w-full h-full absolute top-0 left-0 flex justify-center items-center">
            <div class="container max-w-[1024px] px-[20px] w-full relative flex flex-col justify-center items-center">
                <h1 class="text-center text-2xl font-bold text-white ">
                    Pomme City
                </h1>
                <p class="max-w-[600px] px-[20px] text-center md:text-lg my-[10px] text-sm font-semibold text-gray-200">
                    Tout ce Dont Vous Avez Besoin pour Votre Smartphone : iPhone, Samsung et Plus Encore
                </p>
                <button @click="gotoBoutique()" class="p-[10px] rounded-[5px] text-sm font-bold bg-orange-600 text-gray-100">
                    Acheter maintenant
                </button>
            </div>
        </div>
    </section>
    <section class="w-full relative flex justify-center mt-[50px] items-center">
        <div class="container max-w-[1024px] flex px-[20px] w-full relative justify-center items-center flex-col">
            <div class="w-full md:h-[400px] flex md:justify-between justify-center md:flex-row flex-col items-center relative">
                <div @click="gotoBoutique()" class="md:w-[calc(50%-10px)] cursor-pointer rounded-[10px] mt-[20px] md:mt-0 w-full md:h-full h-[150px] bg-[#F2F2F2] flex justify-center items-center md:flex-col flex-row relative">
                    <div class="md:w-full w-6/12 md:h-[30%] h-full relative flex flex-col justify-center items-center md:pt-[20px] md:pb-[50px] pl-[20px]">
                        <h1 class="text-2xl font-bold text-gray-800 w-full md:w-auto uppercase">Iphone</h1>
                        <p class="text-xs capitalize text-gray-500 w-full md:w-auto font-bold">Tous les iphone de la 7</p>
                    </div>
                    <div class="md:w-full w-6/12 md:h-[70%] h-full relative flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/categorie_iphone.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
                <div @click="gotoBoutique()" class="md:w-[calc(50%-10px)] cursor-pointer rounded-[10px] w-full md:h-full h-[150px] bg-[#DAEEEF] flex justify-center items-center md:flex-col mt-[20px] md:mt-0">
                    <div class="w-6/12 h-full md:h-[30%] md:w-full relative flex flex-col justify-center items-center md:pl-[50px] pl-[20px]">
                        <h1 class="text-2xl font-bold text-gray-800 md:text-center w-full uppercase">Samsung</h1>
                        <p class="text-xs capitalize md:text-center text-gray-500 w-full font-bold">Tous les samsung disponible</p>
                    </div>
                    <div class="w-6/12 h-full md:h-[70%] md:w-full relative flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/categorie_samsung.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
            </div>
            <div class="w-full md:h-[350px]  mt-[20px] flex md:flex-row flex-col justify-between items-center relative">
                <div class="md:w-[calc(25%-10px)] rounded-[10px] w-full md:h-full h-[150px] bg-[#F4F4F4] hidden justify-center items-center md:flex-col flex-row relative">
                    <div class="md:w-full w-6/12 md:h-[35%] h-full relative flex flex-col justify-center items-center md:py-[20px] pl-[20px] md:pl-0">
                        <h1 class="text-2xl font-bold text-gray-800 w-full md:w-auto uppercase">Pochette</h1>
                        <p class="text-xs capitalize text-gray-500 w-full md:w-auto font-bold">Iphone - Samsung</p>
                    </div>
                    <div class="md:w-full w-6/12 md:h-[65%] h-full relative flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/categorie_pochette.png" alt="" class="w-full h-full absolute object-contain object-center">

                    </div>
                </div>
                
                <div @click="gotoBoutique()" class="w-full rounded-[10px] md:h-full h-[150px] bg-[#F3F2F0] flex flex-center items-center md:flex-col flex-row relative  md:mt-0">
                    <div class="md:w-full w-[50%] md:h-[35%] h-full relative flex flex-col justify-center items-center py-[20px]">
                        <p class="text-sm capitalize text-gray-500 font-bold max-w-[80%] md:text-center text-left">Vos différents accessoires sont disponibles</p>
                    </div>
                    <div class="md:w-full w-[50%] md:h-[65%] h-full relative flex justify-center items-center overflow-hidden">
                        <img src="../assets/images/categorie_accessoires2.png" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="my-[50px] py-[50px] w-full relative hidden justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] flex flex-col justify-center items-center relative w-full">
            <div class="w-full relative flex justify-start items-start flex-col md:mb-[40px]">
                <div class="flex items-center">
                    <div class="w-[25px] h-[25px] rounded-full flex justify-center items-center text-white bg-blue-500">
                        <i class="fa-solid fa-layer-group text-sm"></i>
                    </div>
                    <h1 class="ml-[5px] text-sm font-bold uppercase text-blue-500">Toutes les catégories</h1>
                </div>
                <h1 class="text-2xl font-bold text-gray-800">
                    Parcourir par catégorie
                </h1>
            </div>
            <div class="w-full relative md:hidden flex justify-end items-center my-[20px]">
                <button class="w-[35px] h-[35px] rounded-full bg-gray-200 flex justify-center items-center text-gray-800 text-sm font-bold mx-[20px]"><i class="fa-solid fa-chevron-left"></i></button>
                <button class="w-[35px] h-[35px] rounded-full bg-gray-200 flex justify-center items-center text-gray-800 text-sm font-bold"><i class="fa-solid fa-chevron-right"></i></button>
            </div>
            <div class="parent flex w-full md:justify-between justify-start overflow-x-auto items-center relative">
                <div class="m-[10px] mx-[25px] ml-0 flex flex-col justify-center items-center">
                    <div class="flex justify-center items-center w-[75px] h-[75px] min-w-[75px] min-h-[75px] rounded-full bg-gray-100 ">
                        <img src="../assets/logo.png" alt="" class="w-[30px] h-[30px]">
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[5px]">Iphone</h1>
                    <p class="text-xs font-semibold text-gray-500">20 produits</p>
                </div>
                <div class="m-[10px] mx-[25px] flex flex-col justify-center items-center">
                    <div class="flex justify-center items-center w-[75px] h-[75px] min-w-[75px] min-h-[75px] rounded-full bg-gray-100 ">
                        <img src="../assets/logo.png" alt="" class="w-[30px] h-[30px]">
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[5px]">Iphone</h1>
                    <p class="text-xs font-semibold text-gray-500">20 produits</p>
                </div>
                <div class="m-[10px] mx-[25px] flex flex-col justify-center items-center">
                    <div class="flex justify-center items-center w-[75px] h-[75px] min-w-[75px] min-h-[75px] rounded-full bg-gray-100 ">
                        <img src="../assets/logo.png" alt="" class="w-[30px] h-[30px]">
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[5px]">Iphone</h1>
                    <p class="text-xs font-semibold text-gray-500">20 produits</p>
                </div>
                <div class="m-[10px] mx-[25px] flex flex-col justify-center items-center">
                    <div class="flex justify-center items-center w-[75px] h-[75px] min-w-[75px] min-h-[75px] rounded-full bg-gray-100 ">
                        <img src="../assets/logo.png" alt="" class="w-[30px] h-[30px]">
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[5px]">Iphone</h1>
                    <p class="text-xs font-semibold text-gray-500">20 produits</p>
                </div>
                <div class="m-[10px] mx-[25px] flex flex-col justify-center items-center">
                    <div class="flex justify-center items-center w-[75px] h-[75px] min-w-[75px] min-h-[75px] rounded-full bg-gray-100 ">
                        <img src="../assets/logo.png" alt="" class="w-[30px] h-[30px]">
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[5px]">Iphone</h1>
                    <p class="text-xs font-semibold text-gray-500">20 produits</p>
                </div>
                <div class="m-[10px] mx-[25px] mr-0 flex flex-col justify-center items-center">
                    <div class="flex justify-center items-center w-[75px] h-[75px] min-w-[75px] min-h-[75px] rounded-full bg-gray-100 ">
                        <img src="../assets/logo.png" alt="" class="w-[30px] h-[30px]">
                    </div>
                    <h1 class="text-sm font-bold text-gray-800 my-[5px]">Iphone</h1>
                    <p class="text-xs font-semibold text-gray-500">20 produits</p>
                </div>
            </div>
        </div>
    </section>
    <section class="my-[50px] w-full py-[50px] flex justify-center items-center bg-gray-800">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex md:flex-row flex-col justify-center items-center">
            <div class="w-full md:w-[25%]">
                <div class="w-full relative flex justify-start items-start flex-col mb-[10px]">
                    <div class="flex items-center">
                        <div class="w-[25px] h-[25px] rounded-full flex justify-center items-center text-white bg-blue-500">
                            <i class="fa-solid fa-chart-column text-sm"></i>
                        </div>
                        <h1 class="ml-[5px] text-sm font-bold uppercase text-blue-300">Nos produits</h1>
                    </div>
                    <h1 class="text-2xl font-bold text-gray-100">
                        Les plus vendus
                    </h1>
                </div>
            </div>
            <div class="w-full md:w-[75%]">
                <CarouselMost/>
            </div>
        </div>
    </section>
    <section class="my-[50px] w-full relative flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] flex flex-col justify-center items-center relative w-full">
            <div class="w-full relative flex justify-start items-start flex-col md:mb-[40px]">
                <div class="flex items-center">
                    <div class="w-[25px] h-[25px] rounded-full flex justify-center items-center text-white bg-blue-500">
                        <i class="fa-solid fa-gift text-sm"></i>
                    </div>
                    <h1 class="ml-[5px] text-sm font-bold uppercase text-blue-500">Nos offres promotionnelles</h1>
                </div>
                <h1 class="text-2xl font-bold text-gray-800">
                    Découvrez nos offres
                </h1>
            </div>
            <div class="flex w-full justify-between flex-wrap items-center relative">
                <router-link v-for="(offre, index) in produits" :key="index" class="w-[calc(50%-10px)] shadow-10xl rounded-[10px] overflow-hidden h-[240px] bg-gray-100 lg:w-[calc(100%/5-20px)] md:my-[30px] my-[20px] relative" :to="'/boutique/produits/'+ offre._id">
                    <div class="w-full h-[60%] bg-white overflow-hidden flex justify-center items-center relative">
                        <img :src="offre.photos[0]" alt="" class="w-full h-full absolute object-contain object-center">
                    </div>
                    <div class="p-[10px] overflow-hidden rounded-b-[10px]">
                        <h1 class="text-sm font-bold line-clamp-1 text-gray-600">{{ offre.lotName }}</h1>
                        <p>
                            <span class="font-bold text-gray-700">{{ offre.prix_reduit }} <strong class="text-[12px]">FCFA</strong></span> <span class="text-xs text-gray-400 line-through" v-if="offre.prix !== offre.prix_reduit"> - {{ offre.prix }}F</span>
                        </p>
                        <div class="w-full absolute bottom-0 left-0 flex justify-between items-center">
                            <div class="pl-[10px] text-orange-500">
                                <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                <i class="fa-solid fa-star-half-stroke text-[10px] mr-[5px]"></i>
                            </div>
                            <button class="p-[10px] text-xs font-bold bg-orange-600 rounded-br-[10px] text-white rounded-tl-[10px]">- {{ offre.reductionPercentage }} %</button>
                        </div>
                    </div>
                </router-link>
            </div>
            <div v-if="isLoading" class="w-full min-h-[200px] flex justify-center items-center">
                <div class="spinner"></div>
            </div>
            <div class="w-full flex justify-center items-center mt-[30px]">
                <button @click="gotoBoutique()" class="p-[10px] bg-gray-200 text-gray-600 rounded-[5px] text-sm font-bold">Voir toutes les offres</button>
            </div>
        </div>
    </section>
    <section class="my-[50px] py-[50px] w-full relative flex justify-center bg-gray-800 items-center">
        <div class="w-full flex justify-center items-center max-w-[1024px] px-[20px] relative">
            <div class="w-full relative flex md:h-[300px] h-auto justify-center items-center md:flex-row flex-col">
                <div class="md:w-6/12 w-full md:h-full h-[300px] relative flex justify-center items-center overflow-hidden">
                    <img src="../assets/images/iphone15.png" alt="" class="w-full h-full absolute object-contain object-center">
                </div>
                <div class="md:w-6/12 w-full h-full relative flex flex-col items-start">
                    <h1 class="text-3xl font-bold text-white uppercase">Iphone 15 Pro max titanium</h1>
                    <div class="w-full flex justify-start items-center flex-wrap my-[25px]">
                        <div class="w-[90px] h-[90px] mr-[20px] my-[10px] bg-gray-200 flex items-center justify-center relative overflow-hidden rounded-[5px]">
                            <img src="../assets/images/iphone15.png" alt="" class="w-[90%] h-[90%] absolute object-contain object-center">
                        </div>
                        <div class="w-[90px] h-[90px] mr-[20px] my-[10px] bg-gray-200 flex items-center justify-center relative overflow-hidden rounded-[5px]">
                            <img src="../assets/images/iphone15_1.png" alt="" class="w-[90%] h-[90%] absolute object-contain object-center">
                        </div>
                        <div class="w-[90px] h-[90px] mr-[20px] my-[10px] bg-gray-200 flex items-center justify-center relative overflow-hidden rounded-[5px]">
                            <img src="../assets/images/iphone15_2.png" alt="" class="w-[90%] h-[90%] absolute object-contain object-center">
                        </div>
                        <div class="w-[90px] h-[90px] mr-[20px] my-[10px] bg-gray-200 flex items-center justify-center relative overflow-hidden rounded-[5px]">
                            <img src="../assets/images/iphone15_3.png" alt="" class="w-[90%] h-[90%] absolute object-contain object-center">
                        </div>
                    </div>
                    <button class="bg-gray-200 text-gray-600 font-bold text-sm rounded-[5px] p-[10px] w-auto">Achetez maintenant</button>
                </div>
            </div>
        </div>
    </section>
    <section class="my-[50px] py-[50px] w-full flex justify-center items-center relative">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex flex-col justify-center ">
            <h1 class="text-center text-sm font-bold md:text-2xl text-gray-800 mb-[50px]">Les internautes donnent leur avis 🥰 !</h1>
            <div class="w-full relative flex justify-center items-center">
                <CarouselAvis/>
            </div>
        </div>
    </section>

    <FooterComponent/>

</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import CarouselMost from '../components/CarouselMost.vue'
import CarouselAvis from '../components/CarouselAvis.vue'
import { onMounted, ref } from 'vue'
import FooterComponent from '@/components/FooterComponent.vue'
import axios from 'axios'
import { useRouter } from 'vue-router'

const router = useRouter()

function gotoBoutique() {
    router.push("/boutique")
}

const produits = ref([])
const isLoading = ref(false)

const fetchProduits = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/lots/produits/random`);
    produits.value = response.data
    .filter(produit => produit.isOnline) 
    .map(produit => {
      const { prix, prix_reduit } = produit;
      const reductionPercentage = prix_reduit ? Math.floor(((prix - prix_reduit) / prix) * 100) : 0;
      return {
        ...produit,
        reductionPercentage: reductionPercentage, // Pourcentage arrondi à deux décimales
      };
    });
  } catch (err) {
    console.error('Erreur lors du chargement des produits:', err);
  } finally {
    isLoading.value = false;
  }
};


onMounted(() => {
    fetchProduits()
})
</script>
<style scoped>
/* Style du loader */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: rgb(234, 88, 12);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
