import { defineStore } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

interface Produit {
  id: string  // Ajout de l'ID du produit
  name: string
  price: number
  image: string
  capacity: string | null
  imei1: string
  imei2: string
  prix: number,
  prix_reduit: number,
  color: string | null
  quantity: number,
  category: string
}

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: [] as Produit[]
  }),
  actions: {
    addToCart(product: Produit) {
      const existingProduct = this.cart.find(p => 
        p.id === product.id &&  // Utilisation de l'ID pour identifier les produits
        p.capacity === product.capacity && 
        p.color === product.color
      );

      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        this.cart.push(product);
      }
    },
    clearCart() {
      this.cart = []
    }
  },
  persist: true,
})
