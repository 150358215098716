<template>
    <header class="w-screen h-[70px] flex justify-center items-center bg-white shadow-4xl fixed top-0 z-[500000]">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-center items-center">
            <div class="w-full flex relative justify-between items-center">
                <div class="flex items-center">
                    <img src="../assets/logo.png" alt="" class="w-[45px] h-[45px] min-w-[45px]">
                </div>
                <nav :class="{'translate-x-[-100%]': !isOpen, 'translate-x-0': isOpen}" class="md:h-[70px] duration-300 md:translate-x-0 md:relative fixed h-[calc(100vh-70px)] md:bg-transparent md:w-auto bg-orange-100 top-[70px] md:top-0 left-0 w-full">
                    <ul class="flex justify-center md:flex-row flex-col items-center h-full">
                        <li @click="goToHomePage()" class="text-lg hover:bg-orange-100/30 md:translate-x-0 duration-[1000ms] font-semibold cursor-pointer text-gray-500 md:mx-[15px] md:h-full my-[30px] flex justify-center items-center p-[5px]" :class="{'translate-x-[-100%]': !isOpen, 'translate-x-0': isOpen, 'text-orange-600 md:border-b-[5px] border-orange-600': $route.path == '/'}">Accueil</li>
                        <li @click="goToBoutiquePage()" class="text-lg hover:bg-orange-100/30 md:translate-x-0 duration-700 font-semibold cursor-pointer text-gray-500 md:mx-[15px] md:h-full my-[30px] flex justify-center items-center p-[5px]" :class="{'translate-x-[-100%]': !isOpen, 'translate-x-0': isOpen, 'text-orange-600 md:border-b-[5px] border-orange-600': $route.path == '/boutique'}">Boutique</li>
                        <li @click="goToComparatifPage()" class="text-lg hover:bg-orange-100/30 md:translate-x-0 duration-700 font-semibold cursor-pointer text-gray-500 md:mx-[15px] md:h-full my-[30px] flex justify-center items-center p-[5px]" :class="{'translate-x-[-100%]': !isOpen, 'translate-x-0': isOpen, 'text-orange-600 md:border-b-[5px] border-orange-600': $route.path == '/comparatif'}">Comparatif</li>
                        <li @click="goToContactPage()" class="text-lg hover:bg-orange-100/30 md:translate-x-0 duration-700 font-semibold cursor-pointer text-gray-500 md:mx-[15px] md:h-full my-[30px] flex justify-center items-center p-[5px]" :class="{'translate-x-[-100%]': !isOpen, 'translate-x-0': isOpen, 'text-orange-600 md:border-b-[5px] border-orange-600': $route.path == '/contact'}">Contact</li>
                    </ul>
                </nav>
                <div class="flex justify-center items-center">
                    <div class="w-[200px] hidden md:flex relative">
                        <label for="" class="absolute top-[10px] right-[15px] text-sm text-gray-500 font-bold"><i class="fa-solid fa-search"></i></label>
                        <input type="text" class="w-full p-[10px] rounded-[20px] outline-none border border-gray-300 bg-gray-100 text-sm pr-[35px] font-semibold" placeholder="Rechercher un produit...">
                    </div>
                    <button @click="goToOffrePage()" class="relative">
                        <i class="fa-solid fa-gift text-2xl text-gray-500 mx-[25px]"></i> 
                        <div class="absolute top-[-3px] right-[18px] bg-red-600 w-[15px] h-[15px] rounded-[50%] flex justify-center items-center text-xs font-bold text-gray-100 animate-pulse"></div>
                    </button>
                    <button @click="goToPanierPage()" class="relative">
                        <i class="fa-solid fa-cart-shopping text-2xl text-gray-500"></i>
                        <div v-if="cartStore.cart.length" class="absolute top-[-5px] right-[-5px] bg-red-600 w-[15px] h-[15px] rounded-[50%] flex justify-center items-center text-xs font-bold text-gray-100">{{ cartStore.cart.length }}</div>
                    </button>
                    <button class="md:hidden" @click="toggleMenu()">
                        <i v-if="!isOpen" class="fa-solid fa-bars text-3xl text-gray-500 ml-[25px]"></i> 
                        <i v-else class="fa-solid fa-times text-3xl text-gray-500 ml-[25px]"></i>
                    </button>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useCartStore } from '../store/useCartStore.ts'

const router = useRouter()
const cartStore = useCartStore()

function goToHomePage() {
    router.push('/')
}
function goToBoutiquePage() {
    router.push('/boutique')
}
function goToComparatifPage() {
    router.push('/comparatif')
}
function goToContactPage() {
    router.push('/contact')
}
function goToPanierPage() {
    router.push('/panier')
}
function goToOffrePage() {
    router.push('/bon-plan')
}
const isOpen = ref(false)

function toggleMenu() {
    isOpen.value = !isOpen.value
}
</script>

<style>

</style>