<template>
    <HeaderComponent/>
    <section class="w-full mt-[70px] flex relative justify-center items-center bg-gray-200">
        <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-start items-center py-[25px] md:py-[40px]">
            <h1 class="text-gray-500 font-bold text-xs">
                <router-link to="/">Accueil</router-link> / <span class="text-orange-600"> Boutique</span>
            </h1>
        </div>
    </section>
    <section class="my-[50px] mb-[100px] w-full relative flex justify-center items-center">
        <div class="container max-w-[1024px] px-[20px] flex flex-col justify-center items-center relative w-full">
            <div class="w-full flex justify-between items-start flex-col relative">
                <div class="w-full relative mb-[25px]">
                    <div class="w-full relative flex md:flex-row flex-col  md:items-center">
                        <div class="w-full relative flex items-center">
                            <select v-model="selectedCapacite" @change="applyFilters" class="absolute top-0 p-[10px] text-sm font-semibold border rounded-l-[10px] outline-none">
                                <option class="text-sm" value="">Capacités</option>
                                <option class="text-sm" value="64gb">64gb</option>
                                <option class="text-sm" value="128gb">128gb</option>
                                <option class="text-sm" value="256gb">256gb</option>
                                <option class="text-sm" value="512gb">512gb</option>
                            </select>
                            <input v-model="searchTerm" @input="applyFilters" type="text" class="w-full p-[10px] text-sm rounded-[10px] pl-[110px] border outline-none font-semibold" placeholder="Rechercher un produit">
                            <div @click="toggleFilter()" class="w-[30px] h-[30px] min-w-[30px] min-h-[30px] ml-[10px] md:hidden rounded-full bg-gray-100 flex justify-center items-center text-sm font-bold text-gray-700">
                                <i class="fa-solid fa-filter"></i>
                            </div>
                        </div>
                        <div class="p-[10px] px-0 md:pl-[10px] md:flex space-x-[10px]" :class="{'hidden md:flex': !isFilter}">
                            <button @click="selectedEtat = ''; applyFilters()"
                                      :class="['p-[10px] text-sm font-semibold border rounded-[5px] outline-none', 
                                      selectedEtat === '' ? 'bg-orange-600 text-white' : 'bg-white text-gray-700 border-gray-300']">
                                Tous
                            </button>
                            <button @click="selectedEtat = 'Scellé'; applyFilters()"
                                      :class="['p-[10px] text-sm font-semibold border rounded-[5px] outline-none', 
                                      selectedEtat === 'Scellé' ? 'bg-orange-600 text-white' : 'bg-white text-gray-700 border-gray-300']">
                                Scellé
                            </button>
                            <button @click="selectedEtat = 'Venu'; applyFilters()"
                                      :class="['p-[10px] text-sm font-semibold border rounded-[5px] outline-none', 
                                      selectedEtat === 'Venu' ? 'bg-orange-600 text-white' : 'bg-white text-gray-700 border-gray-300']">
                                Venu
                            </button>
                            <button @click="selectedEtat = 'Occasion'; applyFilters()"
                                      :class="['p-[10px] text-sm font-semibold border rounded-[5px] outline-none', 
                                      selectedEtat === 'Occasion' ? 'bg-orange-600 text-white' : 'bg-white text-gray-700 border-gray-300']">
                                Occasion
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Message si aucun produit n'est trouvé -->
                <div v-if="!isLoading && paginatedProduits.length === 0" class="w-full h-[200px] flex justify-center items-center text-center text-gray-600">
                    Aucun produit ne correspond à vos critères.
                </div>

                <!-- Loader -->
                <div v-if="isLoading" class="w-full h-[200px] flex justify-center items-center">
                    <div class="spinner"></div>
                </div>

                <!-- Affichage des produits -->
                <div v-if="!isLoading && paginatedProduits.length > 0" class="w-full relative min-h-screen flex flex-col mt-[20px] lg:mt-0">
                    <div class="flex w-full justify-between flex-wrap items-center relative">
                        <router-link v-for="(offre, index) in paginatedProduits" :key="index" class="w-[calc(50%-10px)] shadow-11xl rounded-[10px] overflow-hidden h-[240px] bg-gray-100 lg:w-[calc(100%/5-20px)] md:mb-[40px] mb-[20px] relative" :to="'/boutique/produits/'+ offre._id">
                            <div class="w-full h-[60%] bg-white overflow-hidden flex justify-center items-center relative">
                                <img :src="offre.photos" alt="" class="w-full h-full absolute object-contain object-center">
                            </div>
                            <div class="p-[10px] overflow-hidden rounded-b-[10px]">
                                <h1 class="text-sm font-bold text-gray-600 line-clamp-1">{{ offre.name }}</h1>
                                <p>
                                    <span class="font-bold text-gray-700">{{ offre.minPrixReduit }} <strong class="text-[12px]">FCFA</strong></span>
                                </p>
                                <div class="w-full absolute bottom-0 left-0 flex justify-between items-center">
                                    <div class="pl-[10px] text-orange-500">
                                        <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                        <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                        <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                        <i class="fa-solid fa-star text-[10px] mr-[5px]"></i>
                                        <i class="fa-solid fa-star-half-stroke text-[10px] mr-[5px]"></i>
                                    </div>
                                    <button class="p-[10px] text-xs font-bold bg-gray-300 rounded-br-[10px] text-gray-800 rounded-tl-[10px]" :class="{'bg-orange-600 font-bold text-white': offre.maxDiffPercentage > 0}"><span v-if="offre.maxDiffPercentage > 0"> - {{ offre.maxDiffPercentage }} %</span><p v-else><span class="hidden md:flex">Voir plus</span> <span class="md:hidden">Plus</span></p></button>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!-- Pagination -->
                <div v-if="!isLoading && paginatedProduits.length > 0" class="w-full mt-[25px] flex justify-center md:justify-end items-center relative">
                    <div v-if="currentPage > 1" @click="changePage(currentPage - 1)"
                        :class="['w-[30px] h-[30px] min-w-[30px] min-h-[30px] cursor-pointer rounded-[5px] mx-[10px] text-sm font-bold flex justify-center items-center', 
                        'text-gray-700 bg-white shadow-4xl']">
                        <i class="fa-solid fa-chevron-left"></i>
                    </div>

                    <div v-for="page in visiblePages" :key="page" @click="changePage(page)"
                        :class="['w-[30px] h-[30px] min-w-[30px] min-h-[30px] cursor-pointer rounded-[5px] mx-[10px] text-sm font-bold flex justify-center items-center', 
                        page === currentPage ? 'text-white bg-orange-600' : 'text-gray-700 bg-white shadow-4xl']">
                        {{ page }}
                    </div>

                    <div v-if="currentPage < totalPages" @click="changePage(currentPage + 1)"
                        :class="['w-[30px] h-[30px] min-w-[30px] min-h-[30px] cursor-pointer rounded-[5px] mx-[10px] text-sm font-bold flex justify-center items-center', 
                        'text-gray-700 bg-white shadow-4xl']">
                        <i class="fa-solid fa-chevron-right"></i>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import HeaderComponent from '../components/HeaderComponent.vue'
import { onMounted, ref, computed } from 'vue'
import FooterComponent from '../components/FooterComponent.vue'
import axios from 'axios'

const produits = ref([])
const filteredProduits = ref([])
const isLoading = ref(false)
const currentPage = ref(1)
const itemsPerPage = 10
const isFilter = ref(false)

function toggleFilter() {
    isFilter.value = !isFilter.value
}

// Variables pour les filtres
const searchTerm = ref("")
const selectedCapacite = ref("")
const selectedEtat = ref("")

// Fonction pour charger les produits
const fetchProduits = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get(`/api/lots`);
    produits.value = response.data
      .map(lot => {
        const produitsOnline = lot.produits.filter(p => p.isOnline);
        const minPrixReduit = Math.min(...produitsOnline.map(p => p.prix_reduit || p.prix));
        const maxPrixReduit = Math.max(...produitsOnline.map(p => p.prix_reduit || p.prix));
        const maxDiffPercentage = Math.max(...produitsOnline.map(p => {
          if (p.prix && p.prix_reduit) {
            return Math.floor(((p.prix - p.prix_reduit) / p.prix) * 100);
          }
          return 0;
        }));

        return {
          ...lot,
          produits: produitsOnline,
          minPrixReduit,
          maxPrixReduit,
          maxDiffPercentage,
        };
      })
      .filter(lot => lot.produits.length > 0); // On ne garde que les lots avec des produits en ligne

    applyFilters();
  } catch (err) {
    console.error('Erreur lors du chargement des produits:', err);
  } finally {
    isLoading.value = false;
  }
};

// Appliquer les filtres
const applyFilters = () => {
  filteredProduits.value = produits.value.filter(produit => {
    const matchesSearch = produit.name.toLowerCase().includes(searchTerm.value.toLowerCase());
    const matchesCapacite = selectedCapacite.value ? produit.produits.some(p => p.capacities.includes(selectedCapacite.value)) : true;
    const matchesEtat = selectedEtat.value ? produit.name.toLowerCase().includes(selectedEtat.value.toLowerCase()) : true;
    return matchesSearch && matchesCapacite && matchesEtat;
  });
  currentPage.value = 1; // Réinitialiser à la première page après le filtrage
};

// Pagination - Obtenir les produits pour la page actuelle
const paginatedProduits = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return filteredProduits.value.slice(start, end);
});

// Nombre total de pages
const totalPages = computed(() => {
  return Math.ceil(filteredProduits.value.length / itemsPerPage);
});

// Changer de page
const changePage = (page) => {
  currentPage.value = page;
};

const visiblePages = computed(() => {
  let start = Math.max(currentPage.value - 2, 1);
  let end = Math.min(start + 4, totalPages.value);

  if (end - start < 4) {
    start = Math.max(end - 4, 1);
  }

  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});

onMounted(() => {
  fetchProduits();
});
</script>


<style scoped>
.shadow-4xl {
  box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
}

/* Style du loader */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: rgb(234, 88, 12);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>