// stores/useClientStore.ts
import { defineStore } from 'pinia'

interface ClientInfo {
  nom: string
  prenoms: string
  email: string
  numero: string
  adresse: string
}

export const useClientStore = defineStore('client', {
  state: () => ({
    info: {} as ClientInfo
  }),
  actions: {
    setClientInfo(clientInfo: ClientInfo) {
      this.info = clientInfo
    },
    clearClientInfo() {
      this.info = {} as ClientInfo
    }
  },
  persist: true, // pour persister l'état dans sessionStorage ou localStorage
})
