<template>
    <HeaderComponent />
    
    <section class="mt-[70px] py-[50px] w-full flex justify-center items-center relative">
      <div class="container max-w-[1024px] px-[20px] w-full relative flex justify-center items-center">
        <div class="w-full flex lg:flex-row flex-col-reverse justify-between items-center h-auto lg:h-[450px] relative">
          
          <div class="lg:w-[calc(50%-10px)] w-full lg:h-full h-[300px] mt-[20px] lg:mt-0 flex justify-between items-between relative flex-wrap">
            <router-link
              v-for="(offre, index) in occasionProduits"
              :key="index"
              class="w-[calc(50%-10px)] h-[calc(50%-10px)] rounded-[10px] mb-[20px] relative overflow-hidden shadow-4xl"
              :to="'/boutique/produits/'+ offre.lotId"
            >
              <div class="w-full h-full absolute top-0 left-0 flex justify-center overflow-hidden">
                <img :src="offre.photos[0]" :alt="offre.nom" class="w-full h-full absolute object-contain" />
              </div>
              <div class="w-full h-full p-[20px] absolute top-0 left-0 flex justify-between flex-col items-start overflow-hidden bg-black/40">
                <div></div>
                <div>
                  <h1 class="uppercase text-white md:line-clamp-2 line-clamp-1 font-bold">{{ offre.lotName }}</h1>
                  <p>
                    <span class="font-bold text-gray-100">{{ offre.prix_reduit }} <strong class="text-[12px]">FCFA</strong></span>
                    <span v-if="offre.prix !== offre.prix_reduit" class="text-xs text-gray-200 font-bold line-through">
                      - {{ offre.prix }}F
                    </span>
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          
          <div class="lg:w-[calc(50%-10px)] w-full lg:h-full h-[250px] flex justify-center overflow-hidden items-center relative bg-black/15 rounded-[10px]">
            <swiper :modules="modules" :slides-per-view="1" :loop="true" :autoplay="{ delay: 3000, disableOnInteraction: false }" class="w-[100%] h-[100%] relative">
              <swiper-slide v-for="(offre, index) in slides" :key="index" class="w-[100%] h-[100%] relative">
                <router-link class="w-full h-full relative overflow-hidden" :to="'/boutique/produits/'+ offre.lotId">
                  <div class="w-full h-[250px] lg:h-[450px] relative top-0 left-0 flex justify-center overflow-hidden">
                    <img :src="offre.photos[0]" :alt="offre.nom" class="w-full h-full absolute object-contain" />
                  </div>
                  <div class="min-w-[335px] w-full h-[250px] lg:h-[450px] p-[20px] absolute top-0 left-0 flex justify-between flex-col items-start overflow-hidden bg-black/40">
                    <div></div>
                    <div>
                      <h1 class="uppercase text-white line-clamp-2 font-bold">{{ offre.lotName }}</h1>
                      <p>
                        <span class="font-bold text-gray-100">{{ offre.prix_reduit }} <strong class="text-[12px]">FCFA</strong></span>
                        <span v-if="offre.prix !== offre.prix_reduit" class="text-xs text-gray-200 font-bold line-through">
                          - {{ offre.prix }}F
                        </span>
                      </p>
                    </div>
                  </div>
                </router-link>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
  
    <FooterComponent />
  </template>
  
  <script setup>
  import { onMounted, ref } from 'vue';
  import axios from 'axios';
  import HeaderComponent from '../components/HeaderComponent.vue';
  import FooterComponent from '@/components/FooterComponent.vue';
  import { Pagination, A11y, Autoplay } from 'swiper/modules';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/autoplay';
  import 'swiper/css/pagination';
  
  const occasionProduits = ref([]);
  const slides = ref([]);
  const modules = [Autoplay, Pagination, A11y];
  
  const fetchProduits = async () => {
    try {
      const response = await axios.get('/api/lots/produits/last4occasion');
      occasionProduits.value = response.data;
    } catch (err) {
      console.error('Erreur lors du chargement des produits:', err);
    }
  };
  
  const fetchProduitsVenu = async () => {
    try {
      const response = await axios.get('/api/lots/produits/venu');
      slides.value = response.data;
    } catch (error) {
      console.error('Failed to fetch fetchProduitsVenu:', error);
    }
  };
  
onMounted(() => {
    fetchProduits();
    fetchProduitsVenu();
});
</script>
  